// license-manager.component.ts
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { License } from 'src/app/data-models/models';
import { LicenseType } from 'src/app/enums/licensesTypes';

@Component({
  selector: 'app-vendor-license-manager',
  templateUrl: './vendor-license-manager.component.html',
  styleUrls: ['./vendor-license-manager.component.scss']
})

export class LicenseManagerComponent implements OnInit, AfterViewInit  {
  @Input() licenses: License[] = [];
  @Output() licensesChange = new EventEmitter<License[]>();
  editingIndex = new Set<number>();
  minDate = new Date();

  public licenseFormArray: FormArray;

  public supportedTypes: Array<string> = Object.values(LicenseType);

  constructor(private fb: FormBuilder) {
    this.licenseFormArray = this.fb.array([]);
  }

  ngAfterViewInit(): void {
      throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.licenseFormArray.clear();

    // Initialize form array with passed licenses
    this.licenses.forEach(license => this.addLicense(license));
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Licenses: " + changes.prop);
    
    this.licenseFormArray.clear();
    
    // this.licenses = changes?.prop;
    this.licenses.forEach(license => this.addLicense(license));
  } 

  addLicense(license = null): void {
    const group = this.fb.group({
      type: [license?.type || '', Validators.required],
      otherType: [license?.otherType || ''],
      number: [license?.number || '', Validators.required],
      issuingAuthority: [license?.issuingAuthority || '', Validators.required],
      expirationDate: [
        license?.expirationDate || '',
        [Validators.required, this.validDate()],
      ],
    });
    this.licenseFormArray.push(group);
  }

  removeLicense(index: number): void {
    this.licenseFormArray.removeAt(index);

    this.emitLicenses();
  }

  editLicense(index: number): void {
    this.editingIndex.add(index);
  }

  saveLicense(index: number): void {
    if (this.licenseFormArray.at(index).valid) {
      this.editingIndex.delete(index);

      this.emitLicenses();
      this.licenseFormArray.clear();
    }
  }

  cancelEdit(index: number): void {
    this.editingIndex.delete(index);
  }

  validDate() {
    return (control) => {
      const date = new Date(control.value);
      return isNaN(date.getTime()) ? { invalidDate: true } : null;
    };
  }

  private emitLicenses(): void {
    const licenses: License[] = this.licenseFormArray.value;
    this.licensesChange.emit(licenses);
  }
}
