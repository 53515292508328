import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { Insurance } from 'src/app/data-models/models';
import { TradesInsuranceTypes } from 'src/app/enums/insuranceTypes';

@Component({
  selector: 'app-insurance-manager',
  templateUrl: './vendor-insurance-manager.component.html',
  styleUrls: ['./vendor-insurance-manager.component.scss']
})
export class InsuranceManagerComponent implements OnInit, AfterViewInit {
  @Input() insurances: Insurance[] = [];
  @Output() insurancesChange = new EventEmitter<Insurance[]>();
  editingIndex = new Set<number>();
  minDate = new Date();

  public insuranceFormArray: FormArray;
  public supportedTypes: Array<string> = Object.values(TradesInsuranceTypes);

  constructor(private fb: FormBuilder) {
    this.insuranceFormArray = this.fb.array([]);
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.insuranceFormArray.clear();

    // Initialize form array with passed insurances
    this.insurances.forEach(insurance => this.addInsurance(insurance));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.insurances && changes.insurances.currentValue) {
      this.insuranceFormArray.clear();
      this.insurances = changes.insurances.currentValue;
      this.insurances.forEach(insurance => this.addInsurance(insurance));
    }
  }

  addInsurance(insurance: Insurance = null): void {
    const group = this.fb.group({
      type: [insurance?.type || '', Validators.required],
      policyNumber: [insurance?.policyNumber || '', Validators.required],
      provider: [insurance?.provider || '', Validators.required],
      coverageAmount: this.fb.group({
        min: [
          this.formatNumber(insurance?.coverageAmount?.min || 0),
          [Validators.required, Validators.min(0)],
        ],
        max: [
          this.formatNumber(insurance?.coverageAmount?.max || 0),
          [Validators.required, Validators.min(0)],
        ],
      }),
      expirationDate: [insurance?.expirationDate || '', [Validators.required, this.validDate()]],
      documents: [insurance?.documents || []],
    });
    this.insuranceFormArray.push(group);
  }
  
  saveInsurance(index: number): void {
    const insuranceGroup = this.insuranceFormArray.at(index);
    if (insuranceGroup.valid) {
      // Parse the formatted numbers back to raw values
      const coverageAmountGroup = insuranceGroup.get('coverageAmount') as FormGroup;
      const min = this.parseNumber(coverageAmountGroup.get('min').value);
      const max = this.parseNumber(coverageAmountGroup.get('max').value);
  
      coverageAmountGroup.patchValue({
        min,
        max,
      });
  
      this.editingIndex.delete(index);
      this.emitInsurances();
      console.log("Saving Insurances...", this.insuranceFormArray.value);
    }
  }
  
  // Utility to format numbers with commas
  private formatNumber(value: number | string): string {
    const numberValue = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(numberValue)) return '';
    return numberValue.toLocaleString('en-US');
  }
  
  // Utility to parse numbers (remove commas)
  private parseNumber(value: string): number {
    return parseFloat(value.replace(/,/g, ''));
  }

  removeInsurance(index: number): void {
    this.insuranceFormArray.removeAt(index);
    this.emitInsurances();
  }

  editInsurance(index: number): void {
    this.editingIndex.add(index);
  }

  cancelEdit(index: number): void {
    this.editingIndex.delete(index);
  }

  validDate() {
    return (control: AbstractControl) => {
      const date = new Date(control.value);
      return isNaN(date.getTime()) ? { invalidDate: true } : null;
    };
  }

  private emitInsurances(): void {
    const insurances: Insurance[] = this.insuranceFormArray.value;
    this.insurancesChange.emit(insurances);
  }
}
