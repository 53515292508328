import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[appNumberMask]'
})
export class NumberMaskDirective implements AfterViewInit {
  @Input('appNumberMask') rawValue: string | number = ''; // Input property to accept the static value

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.applyMask();
  }

  private applyMask(): void {
    const element = this.el.nativeElement;
    const value = this.rawValue?.toString().replace(/,/g, ''); // Remove commas if they exist

    if (this.isNumeric(value)) {
      const formattedValue = this.formatWithCommas(value);
      element.innerText = formattedValue; // Update the inner text of the span/div
    } else {
      console.error('Invalid value for number masking:', this.rawValue);
    }
  }

  private isNumeric(value: string | undefined): boolean {
    return value !== undefined && !isNaN(Number(value));
  }

  private formatWithCommas(value: string): string {
    if (!value) return '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
  }
}
