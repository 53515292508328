export enum TradesInsuranceTypes {
    // General Policies
    GENERAL_LIABILITY = "General Liability",
    WORKERS_COMPENSATION = "Workers Compensation",
    COMMERCIAL_AUTO = "Commercial Auto",
    PROFESSIONAL_LIABILITY = "Professional Liability",
    BUSINESS_OWNERS_POLICY = "Business Owner's Policy (BOP)",
    UMBRELLA_LIABILITY = "Umbrella Liability",
    PROPERTY_INSURANCE = "Property Insurance",
  
    // Specialty Coverage
    EQUIPMENT_INSURANCE = "Equipment Insurance",
    TOOLS_AND_EQUIPMENT = "Tools and Equipment Insurance",
    INSTALLATION_FLOATER = "Installation Floater Insurance",
    BUILDERS_RISK = "Builder's Risk Insurance",
    CYBER_LIABILITY = "Cyber Liability Insurance",
    POLLUTION_LIABILITY = "Pollution Liability Insurance",
    INLAND_MARINE = "Inland Marine Insurance",
    CONTRACTORS_ERRORS_OMISSIONS = "Contractors Errors and Omissions Insurance",
  
    // Employee-Related
    GROUP_HEALTH_INSURANCE = "Group Health Insurance",
    DISABILITY_INSURANCE = "Disability Insurance",
    LIFE_INSURANCE = "Life Insurance",
    EMPLOYEE_PRACTICES_LIABILITY = "Employment Practices Liability Insurance (EPLI)",
  
    // Legal and Contractual
    SURETY_BONDS = "Surety Bonds",
    LICENSE_AND_PERMIT_BONDS = "License and Permit Bonds",
    COMPLETED_OPERATIONS = "Completed Operations Coverage",
  
    // Additional Optional Policies
    LOSS_OF_INCOME = "Loss of Income Insurance",
    BUSINESS_INTERRUPTION = "Business Interruption Insurance",
    CRIME_INSURANCE = "Crime Insurance",
    DATA_BREACH = "Data Breach Insurance",
    COMMERCIAL_GENERAL_LIABILITY_EXTENDED = "Extended Commercial General Liability",
    KEY_PERSON_INSURANCE = "Key Person Insurance",
    TRADE_CREDIT_INSURANCE = "Trade Credit Insurance",
  
    // Industry-Specific
    HVAC_INSURANCE = "HVAC Contractor Insurance",
    ELECTRICAL_INSURANCE = "Electrical Contractor Insurance",
    PLUMBING_INSURANCE = "Plumbing Contractor Insurance",
    ROOFING_INSURANCE = "Roofing Contractor Insurance",
    CONSTRUCTION_DEFECT = "Construction Defect Insurance",
    LANDSCAPING_INSURANCE = "Landscaping Contractor Insurance",
    WELDING_INSURANCE = "Welding Contractor Insurance",
    PAINTING_INSURANCE = "Painting Contractor Insurance",
    MASONRY_INSURANCE = "Masonry Contractor Insurance",
    CARPENTRY_INSURANCE = "Carpentry Contractor Insurance",
    POOL_AND_SPA_INSURANCE = "Pool and Spa Contractor Insurance",
    SOLAR_PANEL_INSURANCE = "Solar Panel Contractor Insurance",
    DEMOLITION_INSURANCE = "Demolition Contractor Insurance",
    EXCAVATION_INSURANCE = "Excavation Contractor Insurance",
  
    // Customer-Specific Coverage
    CLIENT_PROPERTY_DAMAGE = "Client Property Damage Insurance",
    TENANTS_LEGAL_LIABILITY = "Tenant's Legal Liability",
    THIRD_PARTY_BODILY_INJURY = "Third-Party Bodily Injury Insurance",
  
    // Other
    OTHER = "Other"
}
  