export enum LicenseType {
    // General Licenses
    GENERAL_CONTRACTOR = "General Contractor",
    RESIDENTIAL_CONTRACTOR = "Residential Contractor",
    COMMERCIAL_CONTRACTOR = "Commercial Contractor",
  
    // Electrical Licenses
    ELECTRICIAN = "Electrician",
    MASTER_ELECTRICIAN = "Master Electrician",
    LOW_VOLTAGE_TECHNICIAN = "Low Voltage Technician",
    
    // Plumbing and HVAC
    PLUMBING = "Plumbing",
    MASTER_PLUMBER = "Master Plumber",
    HVAC_TECHNICIAN = "HVAC Technician",
    BOILER_OPERATOR = "Boiler Operator",
    GAS_FITTER = "Gas Fitter",
    
    // Specialty Licenses
    ROOFING_CONTRACTOR = "Roofing Contractor",
    FIRE_PROTECTION_TECHNICIAN = "Fire Protection Technician",
    WELDING_CERTIFICATION = "Welding Certification",
    SEPTIC_INSTALLER = "Septic Installer",
    
    // Environmental and Energy
    SOLAR_TECHNICIAN = "Solar Technician",
    ENERGY_AUDITOR = "Energy Auditor",
    WIND_TURBINE_TECHNICIAN = "Wind Turbine Technician",
    
    // Vehicle and Equipment
    COMMERCIAL_DRIVER = "Commercial Driver",
    HEAVY_EQUIPMENT_OPERATOR = "Heavy Equipment Operator",
    
    // Other
    HOME_INSPECTOR = "Home Inspector",
    PEST_CONTROL = "Pest Control",
    LANDSCAPING = "Landscaping",

    OTHER = "Other"
  }
  